<template>
    <v-dialog persistent fullscreen v-model="show">
        <v-card :loading="loading" :disabled="loading">
            <!-- in ios add pt-12 instead of pt-4 -->
            <v-card-text class="d-flex align-center justify-space-between"
            :class="$ios? 'pt-12': 'pt-4'"
            
            >
                <div class="dialog-title">{{ $tr("Edit Profile") }}</div>
                <v-btn text icon :disabled="loading" @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text>
                <v-container fluid>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$tr('Username')"
                                    v-model="form.user_name"
                                    name="username"
                                    :rules="user_name_rules"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field :label="$tr('Name')" v-model="form.name" :rules="name_rules" name="name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$tr('Email')"
                                    v-model="form.email"
                                    :rules="email_rules"
                                    name="email"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$tr('Phone')"
                                    v-model="form.phone"
                                    name="phone"
                                    :rules="phone_rules"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$tr('Password')"
                                    v-model="form.password"
                                    type="password"
                                    name="password"
                                    @input="$refs.form.validate()"
                                    :rules="password_rules"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$tr('Password-Confirmation')"
                                    v-model="form.confirm_password"
                                    type="password"
                                    name="password"
                                    :rules="confirm_password_rules"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex align-center justify-space-between">
                <v-btn :disabled="loading" :loading="loading" text color="primary" @click="show = false">{{
                    $tr("Cancel")
                }}</v-btn>
                <v-btn :disabled="loading || !valid" :loading="loading" text color="primary" @click="submit">{{
                    $tr("Submit")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import rules from "../../../../util/rules/index.js";

export default {
    data() {
        return {
            show: false,
            valid: false,
            loading: false,
            form: {
                user_id: "",
                name: "",
                user_name: "",
                phone: "",
                email: "",
                password: "",
                confirm_password: "",
            },
        };
    },
    props: {
        user_profile: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    models: [{ data: "show", event: "models:show" }],
    watch: {
        async show(v) {
            this.$emit("models:show", v);
            if (v) {
                console.log("refreshing")
                await this.refresh();
            }
        },
    },
    methods: {
        async submit() {
            this.loading = true;
            try {

                const updated_user = (await this.$api.post("user/update/profile", this.form)).data.result.user;

                let notification = {
                    msg: "Profile Updated",
                    color: "success",
                };
                await this.$store.dispatch("user/notify", notification);
                this.$emit("refresh", updated_user);
                this.show = false;
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async refresh() {
            this.loading = true;
            try {
                await this.$nextTick();
                await this.$refs.form.reset();
                await this.$nextTick();
                if (this.user_profile) {
                    this.form.user_name = this.user_profile.user_name;
                    this.form.name = this.user_profile.name;
                    this.form.phone = this.user_profile.phone;
                    this.form.email = this.user_profile.email;
                    this.form.user_id = this.user_profile.user_id
                }
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
    computed: {
        user_name_rules() {
            const field = "Username";
            return [rules.required(field), rules.username(field)];
        },
        name_rules() {
            const field = "Name";
            return [rules.required(field), rules.name(field)];
        },
        phone_rules() {
            const field = "Phone";
            return [rules.phone(field)];
        },
        email_rules() {
            const field = "Email";
            return [rules.email(field)];
        },
        password_rules() {
            const field = "Password";
            return [rules.password(field)];
        },
        confirm_password_rules() {
            const field = "Password-Confirmation";
            return [
                (v) => {
                    if(!this.form.password){
                        return true
                    }
                    if (v === this.form.password) {
                        return true;
                    } else {
                        return "Password-Confirmation and Password Must match";
                    }
                },
                rules.password(field),
            ];
        },
    },
};
</script>

<style lang="sass">
.dialog-title
    font-size: 24px
    font-weight: 700
</style>
